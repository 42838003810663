<script>
import Vue, { computed, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import emitter from '/~/core/emitter'
import ui from '/~/core/ui'
import LayoutMain from '/~/extensions/bank-file-upload/layouts/main.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseUploadFilesArea from '/~/components/base/upload-files/base-upload-files-area.vue'
import BaseUploadFiles from '/~/components/base/upload-files/base-upload-files.vue'
import {
  useBatchOrder,
  useBatchOrderNewPayees,
  useBatchOrderStatusHandler,
} from '/~/composables/batch-order'
import { useLogger } from '/~/composables/logger'

export default {
  name: 'bank-file-upload',
  components: {
    BaseAsidePage,
    BaseIcon,
    BaseLoader,
    BaseUploadFiles,
    BaseUploadFilesArea,
    LayoutMain,
    BaseButton,
  },
  props: {
    extensions: {
      type: Array,
      default: () => ['aba'],
    },
  },
  setup() {
    const { isBatchOrderLoading, createBatchOrder } = useBatchOrder()
    const { batchOrderStatusHandler } = useBatchOrderStatusHandler('business')
    const { missingPayees } = useBatchOrderNewPayees()

    const logger = useLogger('batch-order')

    const uploadFilesElement = ref(null)
    const fileUploadingError = ref('')
    const isInitialized = ref(false)

    const creatingProgress = computed(() => {
      const loadedPercents = uploadFilesElement.value?.loadedPercents ?? 0

      return loadedPercents
    })

    function init() {
      try {
        batchOrderStatusHandler.resetBatchOrder()
        batchOrderStatusHandler.startCheckingLoop()
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: error.message,
        })
      }

      isInitialized.value = true
    }

    async function onFilesUploaded(files) {
      const file = files[0]

      try {
        await createBatchOrder({
          fileUploadId: file.id,
          type: 'business',
        })

        missingPayees.payeeList.resetLocal()
        batchOrderStatusHandler.startCheckingLoop()
      } catch (error) {
        logger.error(error)

        Vue.notify({
          type: 'error',
          text: 'Something went wrong. Please try again.',
        })
      }
    }

    function onFilesUploadingError() {
      fileUploadingError.value = 'Upload failed, please try again'
      uploadFilesElement.value?.resetUploadableFiles()
    }

    function onFilesUploadingStarted() {
      fileUploadingError.value = ''
    }

    function orderCreationFailed(errorText) {
      Vue.notify({
        type: 'error',
        text: errorText,
      })

      uploadFilesElement.value?.resetUploadableFiles()
    }

    onBeforeMount(() => {
      setTimeout(() => {
        emitter.on('batch-order-creation-failed', orderCreationFailed)
        emitter.on('batch-order-creation-cancelled', orderCreationFailed)
      })
    })

    onBeforeUnmount(() => {
      emitter.off('batch-order-creation-failed', orderCreationFailed)
      emitter.off('batch-order-creation-cancelled', orderCreationFailed)
    })

    init()

    return {
      uploadFilesElement,
      fileUploadingError,
      isInitialized,
      creatingProgress,
      isBatchOrderLoading,
      onFilesUploadingError,
      onFilesUploadingStarted,
      onFilesUploaded,
      ui,
      batchOrderStatusHandler,
    }
  },
}
</script>

<template>
  <layout-main>
    <component
      :is="ui.mobile ? 'base-aside-page' : 'div'"
      title="Upload bank file"
      :no-padding="true"
      :back="{ name: 'home' }"
    >
      <div>
        <h1 v-if="!ui.mobile" class="text-2xl">
          {{ $route.meta?.title || 'Upload bank file' }}
        </h1>
        <p class="mt-2 text-eonx-neutral-600">
          Upload compatible ABA file to import a list of payments
        </p>
        <div
          v-if="batchOrderStatusHandler.batchOrderLoader.loading"
          class="mt-10 flex flex-col items-center"
        >
          <base-loader size="xl" />
        </div>
        <template v-else>
          <base-upload-files
            ref="uploadFilesElement"
            :is-list-visible="false"
            :is-action-visible="ui.mobile"
            :is-area-visible="true"
            :extensions="extensions"
            driver="S3Driver"
            class="mt-8"
            @files-uploading-error="onFilesUploadingError"
            @files-uploading-started="onFilesUploadingStarted"
            @files-uploaded="onFilesUploaded"
          >
            <template #area="areaProps">
              <base-upload-files-area
                :is-uploading="areaProps.isUploading"
                :loaded-percents="areaProps.loadedPercents"
                @drop="areaProps.onDrop"
                @click="areaProps.showFileDialog"
              >
                <template #content>
                  <div
                    class="flex flex-col items-center justify-center text-center"
                  >
                    <span class="text-eonx-neutral-600">
                      <base-icon
                        :svg="'heroicons/outline/document'"
                        :size="50"
                      />
                    </span>
                    <div
                      v-if="areaProps.uploadableFiles.length > 0"
                      class="mt-2.5 font-bold"
                    >
                      {{ areaProps.uploadableFiles[0].file.name }}
                    </div>
                    <div v-else class="my-3 text-sm text-eonx-neutral-600">
                      ABA file
                    </div>
                    <template
                      v-if="areaProps.isUploading || isBatchOrderLoading"
                    >
                      <div class="text-sm">
                        Uploading {{ creatingProgress }}%
                      </div>
                      <div
                        class="mt-4 h-2.5 w-80 overflow-hidden rounded-full bg-eonx-neutral-50"
                      >
                        <div
                          class="h-full bg-primary transition-width duration-500"
                          :style="{
                            width: `${creatingProgress}%`,
                          }"
                        />
                      </div>
                    </template>
                    <div v-else class="text-eonx-neutral-600">
                      <span
                        class="font-bold text-primary hover:text-primary-lighten"
                      >
                        Upload ABA file
                      </span>
                      <span v-if="!ui.mobile">or drag and drop</span>
                    </div>
                  </div>
                </template>
                <template #overlay="overlayProps">
                  <div
                    v-if="overlayProps.isDraggingInside"
                    class="absolute left-0 top-0 h-full w-full rounded bg-white opacity-75"
                  />
                  <div v-else />
                </template>
                <template #overlay-content>
                  <div />
                </template>
              </base-upload-files-area>
            </template>

            <template #action="actionProps">
              <base-button
                :disabled="actionProps.isUploading"
                class="w-full"
                @click="actionProps.showFileDialog"
              >
                {{
                  actionProps.isUploading
                    ? `Uploading ${actionProps.$routeloadedPercents}%`
                    : 'Select files to upload'
                }}
              </base-button>
            </template>
          </base-upload-files>
          <div v-if="fileUploadingError" class="mt-4 flex justify-center">
            <div
              class="flex items-center rounded-md bg-orange-50 px-[30px] py-2.5 text-center text-sm text-orange-700"
            >
              <base-icon
                svg="symbion/warning"
                :size="19"
                class="mr-2.5 text-orange-700"
              />
              {{ fileUploadingError }}
            </div>
          </div>
        </template>
      </div>
    </component>
  </layout-main>
</template>
